@font-face {
  font-family: 'Inter';
  font-weight: 100;
  font-style: normal;
  src: local('Inter'),
    url('woff/Inter-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  font-style: italic;
  src: local('Inter'),
    url('woff/Inter-ThinItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  font-style: normal;
  src: local('Inter'),
    url('woff/Inter-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  font-style: italic;
  src: local('Inter'),
    url('woff/Inter-ExtraLightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: local('Inter'),
    url('woff/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: italic;
  src: local('Inter'),
    url('woff/Inter-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: local('Inter'),
    url('woff/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: italic;
  src: local('Inter'),
    url('woff/Inter-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  src: local('Inter'),
    url('woff/Inter-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: italic;
  src: local('Inter'),
    url('woff/Inter-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  font-style: normal;
  src: local('Inter'),
    url('woff/Inter-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  font-style: italic;
  src: local('Inter'),
    url('woff/Inter-ExtraBoldItalic.woff2') format('woff2');
}