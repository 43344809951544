.fr-box,fr-document{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.fr-toolbar .fr-command.fr-btn, .fr-popup .fr-command.fr-btn, .fr-modal .fr-command.fr-btn {
    margin: 4px 0px;
}

.fr-toolbar .fr-command.fr-btn i, .fr-toolbar .fr-command.fr-btn svg, .fr-popup .fr-command.fr-btn i, .fr-popup .fr-command.fr-btn svg, .fr-modal .fr-command.fr-btn i, .fr-modal .fr-command.fr-btn svg {
    margin: 8px 4px;
}

.fr-box.fr-document .fr-wrapper {
    padding: 20px;
}

.fr-popup.placeholder-container {
    max-height: 45vh;
    overflow-y: auto;
    padding: 8px 0;
}


.fr-popup .fr-buttons.placeholder-button:not(.fr-tabs){
    display: flex;
    width: 216px;
    flex-wrap: wrap;
    padding: 0;
}

.fr-popup .fr-buttons.placeholder-button .fr-btn {
    gap: 4px;
    width: 100%;
    display: flex;
    padding: 0 6px;
    margin: 0;
    min-height: 40px;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
}

.fr-popup .fr-buttons .fr-date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fr-popup .fr-buttons .fr-btn .title {
    align-items: center;
    font-size: 14px;
    color: #202931;
    font-weight: 400;
    line-height: 20px;
    font-family: "Inter", Courier, monospace;
}

.fr-popup .fr-buttons .fr-btn .subTitle {
    align-items: center;
    font-size: 12px;
    color: #202931;
    font-weight: 400;
    line-height: 16px;
    font-family: "Inter", Courier, monospace;
}

.fr-chip-label{
    background: #EDEFF2;
    color: #202931;
    height: 24px;
    border-radius: 16px;
    -moz-border-radius: 16px;
    -webkit-border-radius: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-family: "Inter", Courier, monospace;
    padding: 0px 8px;
    margin: 1px 0;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.fr-chip-label.highlighted{
    background: #DAEAA6;
    color: #0F6558;
}