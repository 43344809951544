.MuiAutocomplete-option.Mui-focused  {
    background-color: rgba(15, 101, 88,0.08) !important;
}

.MuiAutocomplete-option[aria-selected='true']  {
    background-color: #DAEAA6 !important;
}

.MuiAutocomplete-option[aria-selected='true']:hover  {
    background-color: #C2DC6A !important;
}

.MuiAutocomplete-clearIndicator:hover {
    background-color: rgba(15, 101, 88,0.08) !important;
}

.MuiAutocomplete-popupIndicator:hover {
    background-color: rgba(15, 101, 88,0.08) !important;
}