@font-face {
    font-family: 'Roboto Mono';
    font-weight: 100;
    font-style: normal;
    src: local('Roboto Mono'),
        url('truetype/RobotoMono-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-weight: 100;
    font-style: italic;
    src: local('Roboto Mono'),
        url('truetype/RobotoMono-ThinItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-weight: 200;
    font-style: normal;
    src: local('Roboto Mono'),
        url('truetype/RobotoMono-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-weight: 200;
    font-style: italic;
    src: local('Roboto Mono'),
        url('truetype/RobotoMono-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-weight: 400;
    font-style: normal;
    src: local('Roboto Mono'),
        url('truetype/RobotoMono-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-weight: 400;
    font-style: italic;
    src: local('Roboto Mono'),
        url('truetype/RobotoMono-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-weight: 500;
    font-style: normal;
    src: local('Roboto Mono'),
        url('truetype/RobotoMono-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-weight: 500;
    font-style: italic;
    src: local('Roboto Mono'),
        url('truetype/RobotoMono-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-weight: 700;
    font-style: normal;
    src: local('Roboto Mono'),
        url('truetype/RobotoMono-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-weight: 700;
    font-style: italic;
    src: local('Roboto Mono'),
        url('truetype/RobotoMono-BoldItalic.ttf') format('truetype');
}